import {picoapp} from 'picoapp';

import newsletter from '../src/components/newsletter';

const defaultState = {};

const components = {
	newsletter,
};

window.app = picoapp(components, defaultState);
window.app.mount();
